export function backendUrl() {
    // local
    //return "http://localhost:8080/api/v1";
    // dev
    //return "https://q1.interb2b.com:8756/api/v1"
    // prod
    return "https://q1.interb2b.com:8755/api/v1"
}

export function ownStyleCSSUrl() {
    return backendUrl() + "/style.css";
}

