import React, { useContext, useState, useEffect} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';

import { MDBContainer } from "mdb-react-ui-kit";

import { StripeKey } from "../data/loginkeys";

const stripePromise = loadStripe(StripeKey.stripeKey);

function Payment(props) {
  
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const {email} = Object.fromEntries([...searchParams]);
  const [submitText, setSubmitText] = useState(null);
  const [outWarning, setOutWarning] = useState(0);

  const options = {
    // passing the client secret obtained in step 3
    clientSecret: props.returnId,
    // Fully customizable with appearance API.
    appearance: {theme: 'flat'},
  };


  useEffect(() => {
   /* return () => {
      if(context.getState()?.stripCancel) {
        context.isStripeCancelFalse();
        context.interAxios.post('/order/' + state.orderId + '/cancel', function(response) {});
      }else{
        context.isStripeCancelTrue();
      }
    }*/
  }, []);


  return (
    <MDBContainer style={{maxWidth: "600px"}}>
      <div className="stripe-container py-5">
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm/>
        </Elements>
      </div>
    </MDBContainer>
    
  );
};

export default Payment;