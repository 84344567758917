// react imports
import React, { useContext, useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import i18n from '../i18n';
// CUSTOM COMPONENTS
import AppContext from '../AppContext';
import { ItemList } from '../data/MusterItem'
import backgroundImage from '../assets/media/images/hero.png'
import logo from '../assets/media/logos/logo_2.png'

import { MDBContainer, 
         MDBCol, 
         MDBRow
          } from 'mdb-react-ui-kit';
import ProductCard from '../components/cards/ProductCard'
import GalleryComponent from '../components/gallery/GalleryComponent'
import FooterModal from '../components/modals/FooterModal'


const HomePage = (props) => {

  

  // STATES
  const [showModalOther, setShowModalOther] = useState(false);
  const [isBodyScroll, setIsBodyScroll] = useState(false);
  const [modalText, setModalText] = useState(false);
  const [fullItemList, setFullItemList] = useState([]);
  const [moreProductsAvailable, setMoreProductsAvailable] = useState(true);
  // MULTILANGUAGE
  const { t } = i18n;

  useEffect(() => {
    setFullItemList(ItemList);
  }, []);

  const loadNewProducts = () => {
    var arrayB= ItemList
    var newArray = fullItemList.concat(arrayB);
    setFullItemList(newArray)
  };

 

	const closeModal = () => {
    setShowModalOther(false)
    setIsBodyScroll(false)
	}

	const showBasketModal = () => {
    setShowModalOther(true)
    setModalText(i18n.t('Basket'))
    setIsBodyScroll(true)
	}

  useEffect(() => {
    
    if(isBodyScroll){
      setTimeout(() => {
        document.body.style.overflow = 'hidden';
        }, 500)
			document.body.style.overflow = 'hidden'
			console.log(document.body.style.overflow)
		}else{
			document.body.style.overflow = 'scroll'
		}
  }, [isBodyScroll]);

  return (
    <>
    <section className="hero" id="hero">
      <img src={backgroundImage} id="backgroundImage" className="backgroundImage-style"/>
      <img className="logo-image" src={logo}/>
    </section>
    <MDBContainer className="p-0" style={{maxWidth: "1366px"}}>
        <section className="about-us d-none d-lg-block" id="about-us">
          <div className="title-text">
            <span>About us</span>
          </div>
          <div className="description-text">
            <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
              sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</span>
          </div>
          <GalleryComponent/>
        </section>

        <section className="catalog" id="catalog">
          <div className="title-text">
            <span>Products</span>
          </div>
          <MDBRow>
            {fullItemList.map((k,n) => {
              return (
                <MDBCol className="d-flex justify-content-center py-3" lg="4" md="6" size="12" key = {n}>
                    <ProductCard msg={k} showBasketModal = {showBasketModal}/>
                </MDBCol>
              )
            })}
          </MDBRow>
          {moreProductsAvailable && (
            <div className="loadmore-button">
                <button
                  onClick={loadNewProducts}
                >
                  
                  <span>SHOW MORE</span>
                </button>
            </div>
               
            )}
        </section>
    </MDBContainer>
      {showModalOther ?
        <FooterModal
          show= {showModalOther}
          handleClose = {closeModal}
          text= {modalText}
        />:false}
    </>
  );
};

export default withTranslation()(HomePage);
