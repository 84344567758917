// REACT IMPORTS
import { useContext, useEffect, useRef, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { withTranslation, Trans } from 'react-i18next';
import i18n from '../../../i18n';
import moment from "moment";


// CUSTOM COMPONENT IMPORTS
import AppContext from '../../../AppContext';

import logo from '../../../assets/media/logos/logo_1.png'
import back_arrow from '../../../assets/media/icons/back-arrow.png'
import hamburger from '../../../assets/media/icons/menu.svg'
import close from '../../../assets/media/icons/close.svg'
import basket_icon from '../../../assets/media/icons/basket.png'
import FooterModal from '../../modals/FooterModal'

import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBSideNav,
  MDBSideNavMenu,
  MDBSideNavItem,
  MDBSideNavLink,
  MDBBtn,
  MDBBadge
} from 'mdb-react-ui-kit';

import Cookies from 'universal-cookie';
const cookies = new Cookies();



const Header = ({props}) => {

  const context = useContext(AppContext);
  const navigate = useNavigate();
  // STATES
  const [showBasic, setShowBasic] = useState(false);
  const [showModalOther, setShowModalOther] = useState(false);
  const [modalText, setModalText] = useState(false);
  const [isScroll, setIsScroll] = useState(false);


  const getLanguage = (language) => {
    if(language === 'EN'){
      context.toEnglish();
      context.onRenderTrue();
    }else{
      context.toGerman();
      context.onRenderTrue();
    }
  };

  const scrollEventListener = () => {
      if(window.scrollY < 50){
        setIsScroll(false)
      }else{
        setIsScroll(true)
      }
  }
  
  useEffect(() => {
    document.addEventListener("scroll", scrollEventListener);
    return () => {
        document.removeEventListener("scroll", scrollEventListener);
    };
  }, []);
  

  const closeModal = () => {
    setShowModalOther(false)
	}

	const showBasketModal = () => {
    setShowModalOther(true)
    setModalText(i18n.t('Basket'))
	}
  const pathname = window.location.pathname.split("/")[window.location.pathname.split("/").length-1];
  console.log(isScroll)
  return (
    <header className="holzhandel-header">
      <div className=" fixed-main-navbar d-flex justify-content-center d-none d-lg-flex">
        <MDBNavbar expand='lg' light className={context.getState()?.isScroll ? "navbar-container scroll" : "navbar-container"} >
        <MDBContainer style={{maxWidth: "1366px", height: "72px"}}>
          <div className="d-none d-lg-block">
            <MDBNavbarBrand>
                <img
                    src={logo}
                    alt=''
                    loading='lazy'
                />
            </MDBNavbarBrand>
          </div>
          <MDBNavbarNav className='mr-auto mb-2 mb-lg-0'>
            <div className="d-flex justify-content-start align-items-center" style={{width: "100%"}}>
              <div className="d-flex justify-content-end align-items-center">
                <MDBNavbarItem link childTag='button' onClick={() => getLanguage('EN')}>
                  <MDBNavbarLink active={cookies.get('mylanguage')?.language === 'en'} href='#'  className="en-item">EN</MDBNavbarLink>
                </MDBNavbarItem>
                <MDBNavbarItem link childTag='button' onClick={() => getLanguage('DE')} className="de-item">
                  <MDBNavbarLink active={cookies.get('mylanguage')?.language === 'de'} href='#' className="de-item">DE</MDBNavbarLink>
                </MDBNavbarItem>
              </div>
              <div className="d-flex justify-content-end align-items-center" style={{width: "100%"}}>
                <MDBNavbarItem  onClick={() => { 
                                if(pathname === "detailpage"){
                                  navigate('/homepage')
                                  setTimeout(() => {context.onSetNavChange(true)
                                    context.onSetActiveItem("1");
                                    window.scrollTo({
                                        top: 0,
                                        behavior: "smooth"
                                      }) },300)
                                }else{
                                  context.onSetNavChange(true)
                                  context.onSetActiveItem("1");
                                  window.scrollTo({
                                      top: 0,
                                      behavior: "smooth"
                                    }) 
                                }
                                setTimeout(() => {context.onSetNavChange(false)},600)
                                }}>
                  <MDBNavbarLink  active={context.getState()?.activeItem === "1"}>HOME</MDBNavbarLink>
                </MDBNavbarItem>
                <MDBNavbarItem  onClick={() => { 
                                if(pathname === "detailpage"){
                                  navigate('/homepage')
                                  setTimeout(() => {context.onSetNavChange(true)
                                    context.onSetNavChange(true)
                                    var element = document.getElementById('about-us');
                                    var headerOffset = 100;
                                    var elementPosition = element.getBoundingClientRect().top;
                                    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                                    context.onSetActiveItem("2");
                                    window.scrollTo({
                                        top: offsetPosition,
                                        behavior: "smooth"
                                      })  },300)
                                }else {
                                  context.onSetNavChange(true)
                                    var element = document.getElementById('about-us');
                                    var headerOffset = 100;
                                    var elementPosition = element.getBoundingClientRect().top;
                                    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                                    context.onSetActiveItem("2");
                                    window.scrollTo({
                                        top: offsetPosition,
                                        behavior: "smooth"
                                      })
                                }
                                setTimeout(() => {context.onSetNavChange(false)},600)
                                }}>
                  <MDBNavbarLink  active={context.getState()?.activeItem === "2"}>ABOUT US</MDBNavbarLink>
                </MDBNavbarItem>
                <MDBNavbarItem onClick={() => {
                                if(pathname === "detailpage"){
                                  navigate('/homepage')
                                  setTimeout(() => { context.onSetNavChange(true)
                                    var element = document.getElementById('catalog');
                                    var headerOffset =0;
                                    var elementPosition = element.getBoundingClientRect().top;
                                    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                                    context.onSetActiveItem("3");
                                    window.scrollTo({
                                        top: offsetPosition,
                                        behavior: "smooth"
                                    })  },300)
                                }else {
                                  context.onSetNavChange(true)
                                  var element = document.getElementById('catalog');
                                  var headerOffset =0;
                                  var elementPosition = element.getBoundingClientRect().top;
                                  var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                                  context.onSetActiveItem("3");
                                  window.scrollTo({
                                      top: offsetPosition,
                                      behavior: "smooth"
                                  })
                                }
                                setTimeout(() => {context.onSetNavChange(false)},600)
                                }}>
                  <MDBNavbarLink  active={context.getState()?.activeItem === "3"}>PRODUCTS</MDBNavbarLink>
                </MDBNavbarItem>
                <button type="button" className="basket-button" onClick={showBasketModal}>
                  <img className="basket-icon" src={basket_icon}/>
                  {window.localStorage.getItem('count') > 0 && (<MDBBadge pill className="basket-badge">{window.localStorage.getItem('count')}</MDBBadge>)}
                  
                </button>
              </div>
            </div>
          </MDBNavbarNav>
        </MDBContainer>
        </MDBNavbar>
      </div>
      <div className=" sidenav-bottom-respo d-block d-lg-none">
      {!isScroll ?
        <div className={pathname === "detailpage" ? "sidenav-menu-respo detailpage" : "sidenav-menu-respo "} >
            
              <img src={logo} height='30' className="respo-logo"/>
            
            <MDBBtn onClick={() => setShowBasic(!showBasic)} className={isScroll ? "sidenavButton scroll" : "sidenavButton"}>
              <img src={hamburger} />
            </MDBBtn>
        </div> 
      :
      <div className="d-flex align-items-center justify-content-end px-4" style={{height: "80px"}}>
        
        <MDBBtn onClick={() => setShowBasic(!showBasic)} className={isScroll? "sidenavButton scroll" : "sidenavButton"}>
          <img src={hamburger} />
        </MDBBtn>
    </div> 
      } 
       
        <MDBSideNav isOpen={showBasic} absolute right getOpenState={(e) => setShowBasic(e)}>
        <MDBSideNavMenu>
        <div className="d-flex justify-content-between align-items-center px-4" style={{height: "80px"}}>
          <div className="d-flex justify-content-start align-items-center language-menu-list">
            <MDBSideNavItem link childTag='button' onClick={() => getLanguage('EN')}>
              <MDBSideNavLink active={cookies.get('mylanguage')?.language === 'en'} href='#'  className="en-item">EN</MDBSideNavLink>
            </MDBSideNavItem>
            <MDBSideNavItem link childTag='button' onClick={() => getLanguage('DE')} className="de-item">
              <MDBSideNavLink active={cookies.get('mylanguage')?.language === 'de'} href='#' className="de-item">DE</MDBSideNavLink>
            </MDBSideNavItem>
          </div>
          <div className="d-flex justify-content-start align-items-center">
            <MDBBtn onClick={() => setShowBasic(!showBasic)} className="sidenavButton">
              <img src={hamburger} />
            </MDBBtn>
          </div>
        </div>
        <div className="side-menu-list">
        <MDBSideNavItem  onClick={() => { 
                          if(pathname === "detailpage"){
                            navigate('/homepage')
                            context.onSetNavChange(true)
                            context.onSetActiveItem("1");
                            window.scrollTo({
                                top: 0,
                                behavior: "smooth"
                              }) 
                            setTimeout(() => {setShowBasic(!showBasic); context.onSetNavChange(false)},300)
                          }else{
                            context.onSetNavChange(true)
                          context.onSetActiveItem("1");
                          window.scrollTo({
                              top: 0,
                              behavior: "smooth"
                            }) 
                          setTimeout(() => {setShowBasic(!showBasic); context.onSetNavChange(false)},300) 
                          }
                          
                          }}>
            <MDBSideNavLink  active={context.getState()?.activeItem === "1"}>HOME</MDBSideNavLink>
          </MDBSideNavItem>
          <MDBSideNavItem onClick={() => {
                          context.onSetNavChange(true)
                          var element = document.getElementById('catalog');
                          var headerOffset =0;
                          var elementPosition = element.getBoundingClientRect().top;
                          var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                          context.onSetActiveItem("3");
                          window.scrollTo({
                              top: offsetPosition,
                              behavior: "smooth"
                          })
                          setTimeout(() => {setShowBasic(!showBasic); context.onSetNavChange(false)},300)
                          }}>
            <MDBSideNavLink  active={context.getState()?.activeItem === "3"}>PRODUCTS</MDBSideNavLink>
          </MDBSideNavItem>
          <div  className="basket-button" onClick={showBasketModal}>
            <img className="basket-icon" src={basket_icon}/>
            {window.localStorage.getItem('count') > 0 && (<MDBBadge pill className="basket-badge">{window.localStorage.getItem('count')}</MDBBadge>)}
          </div>
        </div>
        </MDBSideNavMenu>
        </MDBSideNav>
      </div>
      
        <FooterModal
          show= {showModalOther}
          handleClose = {closeModal}
          text= {modalText}
        />
    </header>
  );
};

export default withTranslation()(Header);
