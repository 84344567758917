import React, { Component } from 'react';


class ShipingAndBillingData extends Component {

  render() {
    return (
      <>
    <p><strong className="font-weight-bold">How to buy</strong></p>
    <p>By using the ONLINE STORE menu item on our website, you can choose either&nbsp;<strong>from amongst our ready jewellery or you can design your own, unique jewellery.</strong></p>
    <p><strong className="font-weight-bold">1. Buying ready jewellery:</strong></p>
    <p>Browse through the product categories&nbsp;(bracelet, medallion, key ring, earrings, cufflinks)&nbsp;and our range.</p>
    <p>Once you have chosen a piece of jewellery and the appropriate currency, you can then buy by clicking on the PURCHASE button.</p>
    <p>The parameters of the selected jewellery appear, which, in the case of a bracelet, is the chance to choose the length of the strap, for medallions the length of the chain and the section for writing the greetings&rsquo; card.</p>
    <p>Once it has been confirmed, the product goes into the shopping basket and then by filling out the exact details and choosing the method of payment, our system takes you to the bank interface.</p>
    <p><strong className="font-weight-bold">2. By clicking on Jewellery designer, you can design your own, unique jewellery!</strong></p>
    <p>Designing jewellery:</p>
    <p>1. Select the product category (bracelet, medallion, key ring, earrings, cufflinks)</p>
    <p>2. Select the size and colour of the strap&nbsp;(in the case of a bracelet, medallion, key ring)</p>
    <p>3. Select the precious metal (silver, white &ndash; yellow &ndash; rose gold)</p>
    <p>4. Select from our collections (Original,&nbsp;Monochrome, Chocolate)</p>
    <p>Continue with the NEXT button where the empty jewellery strap appears, which you can fill up with the yachting symbols, numbers and decorative elements you can find in the top row, all according to your individual taste.</p>
    <p>By clicking on the ensigns and holding the mouse button down, you can easily drag and drop them on to the jewellery strap.</p>
    <p>Select the currency and the system will automatically generate the prices.</p>
    <p>After it has been confirmed, the parameters and details of the compiled jewellery will appear, together with the section for sending the greetings card, which you can complete as you wish. You can continue with the purchase or delete any jewellery you do not require. Once it has been confirmed, you are taken to the bank transfer interface, where you can choose the method of payment and, by entering the correct data, move on to the interface of the selected bank. You can find out here about the quality of our jewellery and the guarantees we provide. Acceptance of the approval statement is required in the Security column.</p>
    <p><strong className="font-weight-bold">Methods of payment</strong></p>
    <p>Payment by card</p>
    <p>&nbsp;Payment can be made with the OTP Bank Nyrt. system, using the selected currency. Once the order has been made live, the system will start the payment process and direct you to the OTP Bank Nyrt. payment interface, where you can give your bank card details in 100% security and make the payment. Following successful completion of the payment, we will send you an immediate confirmation email and then in a few hours the certified digital copy of the invoice.&nbsp;</p>
    <p>&nbsp;If payment is unsuccessful, your order isn&rsquo;t lost, our system will change it to prepayment and send you an information reply email about this.</p>
    <p>Prepayment</p>
    <p>&nbsp;Once the order has been made live, we will immediately send you a confirmation email, on the basis of which you can make the payment. We will send a digital copy of the invoice within a few hours of the completion of the payment to the specified email address.</p>
    <p>PayPal payment</p>
    <p>&nbsp;You can make the payment with the PAYPAL system using the selected currency. Once the order has been made live, the system starts the payment process and takes you to the PAYPAL payment interface, where you can give your banking details in 100% security. Following successful completion of the payment, we will send you an immediate confirmation email and then in a few hours the certified digital copy of the invoice.&nbsp;</p>
    <p>&nbsp;If payment is unsuccessful, your order isn&rsquo;t lost, our system will change it to prepayment and send you an information reply email about this.</p>
    <p>Once the necessary data have been given, you can make the order live using the PURCHASE button. The payment process will begin on the basis of the selected method of payment and our system will then send a confirmation email, as well as, in the case of successful payment, a digital copy of the issued invoice.</p>
    <p><strong className="font-weight-bold">Delivery and shipping fees</strong></p>
    <p>The Seller ships to EU countries, Switzerland, Norway, Russia, the USA, the United Arab Emirates, Australia and Japan.&nbsp;In order to make a purchase, the invoice address and the delivery address need to be in these countries.</p>
    <p>Delivery dates are binding only if recorded in writing. The delivery period shall start after the purchase price is received.&nbsp;The delivery period may be 14 working days, while in the case of countries subject to duty, this may even be as much as 20 days due to the differing customs handling practises. The shipment and delivery of the purchased goods will be completed by transportation or courier companies based on the level of the final value of the invoice. /FEDEX, GROUP 4 SECURITY/</p>
    <p>If the item(s) cannot be delivered due to reasons within the Buyer&rsquo;s control, extra delivery costs may arise. The Buyer shall bear the delivery costs of shipping to and fro in this case. This is not binding in regard to the conditions outlined in paragraph 8.</p>
    <div><br/></div>
</>
    );
  }
}

export default ShipingAndBillingData;
