import { getDB, customerObjStoreName } from './db';
const id = 'customer';
const currencyId = 'currency';

/*function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}*/

export async function initCustomerInfo() {
    let db = await getDB();
    let customer = {
        email: "string",
        phone: "string",
        billing: {
                name: "string",
                postcode: "string",
                country: "string",
                region: "string",
                city: "string",
                address: "string",
                note: "string",
                vatCode: "string",
                companyRegistrationNumber: "string",
                nationality: "string",
                identityDocumentType: "PERSONAL_ID",
                identityDocument: "string",
                birthPlace: "string",
                birthDate: "2023-07-06"
        },
        shipping: {
                name: "string",
                postcode: "string",
                country: "string",
                region: "string",
                city: "string",
                address: "string",
                note: "string"
        }
    };
    await db.put(customerObjStoreName, customer, id);
    await db.put(customerObjStoreName, 'EUR', currencyId);
}

//////////////// CUSTOMER ///////////////////

export async function getCustomerInfo() {
    let db = await getDB();

    let customer = await db.get(customerObjStoreName, id);

    return customer;
}

export async function setCustomerInfo(customer) {
    let db = await getDB();

    await db.put(customerObjStoreName, customer, id);
}

export async function clearCustomerInfo() {
    let db = await getDB();

    db.clear(customerObjStoreName);
}

//////////////// CURRENCY ///////////////////

export async function setCurrency(currency) {
    let db = await getDB();

    await db.put(customerObjStoreName, currency, currencyId);
}

export async function getCurrency(currency) {
    let db = await getDB();

    return await db.get(customerObjStoreName, currencyId);
}

