import { getDB, basketObjStoreName } from './db';

/*function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}*/

/*
let callbacks = [];

export function registerToBasketUpdate(fun) {
    callbacks.push(fun);
}

export function unregisterFromBasketUpdate(fun) {
    callbacks = callbacks.filter(item => item !== fun);
}

notify() {
    for (let callback of callbacks) {
        callback();
    }
}
*/

export async function putSomeData() {
    let db = await getDB();

    {
        const tx = db.transaction(basketObjStoreName, 'readwrite');
        await Promise.all([
          tx.store.add({
            "title": "Lorem Ipsum dolor 40kg",
            "firstImageUrl": "",
            "description": "Lorem Ipsum dolor",
            "price": 42.40,
            "mengeTyp": "bag",
            "shippingcost": 0,
            "count": 1
        }),
        tx.store.add({
            title: "Lorem Ipsum dolor 40kg",
            firstImageUrl: "",
            description: "Lorem Ipsum dolor",
            price: 42.40,
            mengeTyp: "bag",
            shippingcost: 0,
            count: 2
        }),
        tx.done
        ]);
    }

}

export async function getAllBasketItems() {
    let db = await getDB();

    let allSavedItems = await db.getAll(basketObjStoreName);
    return allSavedItems;
}

export async function addItemToBasket(product) {
    let db = await getDB();

    if (product.count === undefined) {
        product.count = 1;
    }

    await db.add(basketObjStoreName, product);
    await loadItemsCount();
}

export async function clearBasket() {
    let db = await getDB();

    db.clear(basketObjStoreName);
    await loadItemsCount();
}

export async function deleteBasketById(item) {
    let db = await getDB();
    await db.delete(basketObjStoreName, item.basketId);
    await loadItemsCount();
}

export async function updateBasketItem(item) {
    let db = await getDB();
    if (item.count === 0) {
        await db.delete(basketObjStoreName, item.basketId);
    } else {
        await db.put(basketObjStoreName, item);
    }
    await loadItemsCount();
}

export async function updateBasketItems(itemlist) {
    let db = await getDB();
    for (let item of itemlist) {
        if (item.count === 0) {
            await db.delete(basketObjStoreName, item.basketId);
        } else {
            await db.put(basketObjStoreName, item);
        }
    }
    await loadItemsCount();
}

export async function getBasketItemsCount() {
    let db = await getDB();
    let allSavedItems = await db.getAll(basketObjStoreName);
    /*let sum = 0;
    for (let item of allSavedItems) {
        console.log(item);
        sum += item.count;
    }*/
    return allSavedItems.map((value) => value.count).reduce((total, curr) => total + curr, 0);
}

export async function loadItemsCount() {
	getBasketItemsCount().then((count) => {
        console.log(count)
      window.localStorage.setItem('count', count);
	})
}