import React, { Component } from "react";
import { setCustomerInfo, getCustomerInfo } from '../db/customer';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Toggle from "react-toggle";
import { MDBBtn, MDBDatepicker, MDBSelect } from 'mdb-react-ui-kit';
import { withTranslation } from 'react-i18next';
import i18n from '../../i18n';

class ProfileForm extends Component {

  constructor(props) {
    super(props);
      this.state = {addresseSame: false,
                    email: "",
                    phone: "",
                    identityDocument: "",
                    billingName: "",
                    billingPostcode: "",
                    billingRegion: "",
                    billingCity: "",
                    billingAddress: "",
                    billingCountry: "",
                    billingNote: "",
                    vatCode: undefined,
                    shippingName: "",
                    shippingPostcode: "",
                    shippingCity: "",
                    shippingAddress: "",
                    shippingRegion: "",
                    shippingCountry: "",
                    shippingNote: "",
                    vatSetting:false,
                    identityDocumentType: "none"
                  
      };
    };

  componentDidMount() {
      let self = this;
    getCustomerInfo().then((customer) => {
      self.setState({   email: customer?.email !== undefined ? customer.email : "",
                        phone: customer?.phone !== undefined ? customer.phone : "",
                        billingName: customer?.billing?.name !== undefined ? customer.billing.name : "",
                        billingPostcode: customer?.billing?.postcode !== undefined ? customer.billing.postcode : "",
                        billingRegion: customer?.billing?.region !== undefined ? customer.billing.region : "",
                        billingCity: customer?.billing?.city !== undefined ? customer.billing.city : "",
                        billingAddress: customer?.billing?.address !== undefined ? customer.billing.address : "",
                        billingCountry: customer?.billing?.country !== undefined ? customer.billing.country : "",
                        billingNote: customer?.billing?.note !== undefined ? customer.billing.note : "",
                        vatCode: customer?.billing?.vatCode !== undefined ? customer.billing.vatCode : "",
                        companyRegistrationNumber: customer?.billing?.companyRegistrationNumber !== undefined ? customer.billing.companyRegistrationNumber : "",
                        nationality: customer?.billing?.nationality !== undefined ? customer.billing.nationality : "",
                        identityDocumentType: customer?.billing?.identityDocumentType !== undefined ? customer.billing.identityDocumentType : "none",
                        identityDocument: customer?.billing?.identityDocument !== undefined ? customer.billing.identityDocument : "",
                        birthPlace: customer?.billing?.birthPlace !== undefined ? customer.billing.birthPlace : "",
                        birthDate: customer?.billing?.birthDate !== undefined ? customer.billing.birthDate : "",
                        shippingName: customer?.shipping?.name !== undefined ? customer.shipping.name : "",
                        shippingPostcode: customer?.shipping?.postcode !== undefined ? customer.shipping.postcode : "",
                        shippingCity: customer?.shipping?.city !== undefined ? customer.shipping.city : "",
                        shippingAddress: customer?.shipping?.address !== undefined ? customer.shipping.address : "",
                        shippingRegion: customer?.shipping?.region !== undefined ? customer.shipping.region : "",
                        shippingCountry: customer?.shipping?.country !== undefined ? customer.shipping.country : "",
                        shippingNote: customer?.shipping?.note !== undefined ? customer.shipping.note : ""});
    });
  }

  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onPhoneNumber = event => {
    this.setState({ phoneNumber: event });
  };
 //this.props.basketItems.map(item => item.hufPrice * item.count).reduce((total, value) => total+value, 0) >= 300000
  onCustomer = (e) => {
    e.preventDefault();
    let isTallPrice = this.props.basketItems.map(item => item.hufPrice * item.count).reduce((total, value) => total+value, 0) >= 300000
    let customer = {
      email: this.state.email,
      phone: this.state.phone,
      billing: {
          name: this.state.billingName,
          postcode: this.state.billingPostcode,
          region: this.state.billingRegion,
          city: this.state.billingCity,
          address: this.state.billingAddress,
          country: this.state.billingCountry,
          note: this.state.billingNote,
          vatCode: this.state.vatSetting === true ? this.state.vatCode : undefined,
          companyRegistrationNumber: this.state.vatSetting === true && isTallPrice ? this.state.companyRegistrationNumber : undefined,
          nationality: this.state.vatSetting === false && isTallPrice ? this.state.nationality : undefined,
          identityDocumentType: this.state.vatSetting === false && isTallPrice ? this.state.identityDocumentType : undefined,
          identityDocument: this.state.vatSetting === false && isTallPrice ? this.state.identityDocument : undefined,
          birthPlace: this.state.vatSetting === false && isTallPrice ? this.state.birthPlace : undefined,
          birthDate: this.state.vatSetting === false && isTallPrice ? this.state.birthDate : undefined,
      },
      shipping: {
          name: this.state.addresseSame === true  ? this.state.shippingName : this.state.billingName,
          postcode: this.state.addresseSame === true  ? this.state.shippingPostcode : this.state.billingPostcode,
          city: this.state.addresseSame === true  ? this.state.shippingCity : this.state.billingCity,
          address: this.state.addresseSame === true  ? this.state.shippingAddress : this.state.billingAddress,
          region: this.state.addresseSame === true  ? this.state.shippingRegion : this.state.billingRegion,
          country: this.state.addresseSame === true  ? this.state.shippingCountry : this.state.billingCountry,
          note: this.state.addresseSame === true  ?  this.state.shippingNote : this.state.billingNote,
      }
    };
    setCustomerInfo(customer);
    this.props.onShowValidData();
  }

  render() {
      let date = new Date();
      date.setFullYear( date.getFullYear() - 18 );
      let isTallPrice = this.props.basketItems.map(item => item.hufPrice * item.count).reduce((total, value) => total+value, 0) >= 300000
    return (
      <form className="holzhandel-profileform" onSubmit={(e) => this.onCustomer(e)}>
        <div className="white-panel">
        <span>Email</span>
        <input label='Email input' 
              id='email' 
              type='text'
              name="email"
              onChange={this.changeHandler} 
              value={this.state.email} 
              required 
        />
        <span>{i18n.t('Phone')}</span>
        <PhoneInput
            country="hu"
            preferredCountries={['hu']}
            value={this.state.phone}
            onChange={phone => this.setState({ phone })}
            required 
        />
        <div
          className='toggle-with-label'
          onClick={() => this.setState({ vatSetting: !this.state.vatSetting })}
        >
          <Toggle
            checked={this.state.vatSetting}
            name='vatSetting'
            id='vatSetting'
            className="jc-toggle"
            readOnly
          />
          {i18n.t('im_required_to_pay_vat')}
        </div>
        <span>{this.state.vatSetting === true ? i18n.t('Company_name') : i18n.t('Name')}</span>
        <input label='Billing name input' 
              id='billingName' 
              type='text'
              name="billingName"
              onChange={this.changeHandler} 
              value={this.state.billingName} 
              required
        />
        <span>{i18n.t('Postcode')}</span>
        <input label='Billing postcode input' 
              id='billingPostcode' 
              type='textc'
              name="billingPostcode"
              onChange={this.changeHandler} 
              value={this.state.billingPostcode}
              required
        />
        <span>{i18n.t('City')}</span>
        <input label='Billing city input' 
              id='billingCity' 
              type='text'
              name="billingCity"
              onChange={this.changeHandler} 
              value={this.state.billingCity} 
              required
        />
        <span>{i18n.t('Address')}</span>
        <input label='Billing address input' 
              id='billingAddress' 
              type='text'
              name="billingAddress"
              onChange={this.changeHandler} 
              value={this.state.billingAddress} 
              required
        />
        <span>{i18n.t('Region')}</span>
       <input label='Billing region input' 
             id='billingRegion' 
             type='text'
             name="billingRegion"
             onChange={this.changeHandler} 
             value={this.state.billingRegion} 
       />
        <span>{i18n.t('Country')}</span>
        <input label='Billing country input' 
              id='billingCountry' 
              type='text'
              name="billingCountry"
              onChange={this.changeHandler} 
              value={this.state.billingCountry} 
              required
        />
        <span>{i18n.t('Note')}</span>
        <input label='Billing note input' 
              id='billingNote' 
              type='text'
              name="billingNote"
              onChange={this.changeHandler} 
              value={this.state.billingNote} 
        />

        {this.state.vatSetting === true  ? <>
        <span>{i18n.t('Vat_code')}</span>
        <input label='VAT Code' 
              id='vatCode' 
              type='text'
              name="vatCode"
              onChange={this.changeHandler} 
              value={this.state.vatCode} 
              required={this.state.vatSetting === true ? true: false}
        />
        </>:false}

        {isTallPrice? <>
        <div className="purchases-text">
        <span>{i18n.t('For_purchases_over_HUF_300000') + "*"}</span>
        </div>
        {this.state.vatSetting === true  ? 
        <>
        <span>{i18n.t('Company_registration_number')}</span>
        <input label='VAT Code' 
              id='companyRegistrationNumber' 
              type='text'
              name="companyRegistrationNumber"
              onChange={this.changeHandler} 
              value={this.state.companyRegistrationNumber} 
              required={isTallPrice && this.state.vatSetting === true ? true : false}
        />
        </> 
        : 
        <>
        <span>{i18n.t('Identity_document_type')}</span>
        <MDBSelect
            data={[
            { text: 'Select', value: "PERSONAL_ID", disabled: true, defaultSelected: this.state.identityDocumentType === "none" },
            { text: i18n.t('Personal_Id'), value: "PERSONAL_ID", defaultSelected: this.state.identityDocumentType === "PERSONAL_ID" },
            { text: i18n.t('Passport'), value: "PASSPORT", defaultSelected: this.state.identityDocumentType === "PASSPORT" },
            { text: i18n.t('Driving_Licence'), value: "DRIVING_LICENCE", defaultSelected: this.state.identityDocumentType === "DRIVING_LICENCE" },
            ]}
            onValueChange={(e) => this.setState({identityDocumentType: e.value})}
        />
        <span>{i18n.t('Identity_document')}</span>
        <input label='Identity input' 
              id='identityDocument' 
              type='text'
              name="identityDocument"
              onChange={this.changeHandler} 
              value={this.state.identityDocument} 
              required={isTallPrice && this.state.vatSetting === false ? true : false}
        />
        <span>{i18n.t('Nationality')}</span>
        <input label='VAT Code' 
              id='nationality' 
              type='text'
              name="nationality"
              onChange={this.changeHandler} 
              value={this.state.nationality} 
              required={isTallPrice && this.state.vatSetting === false ? true : false}
        />
        <span>{i18n.t('Birth_place')}</span>
        <input label='VAT Code' 
              id='birthPlace' 
              type='text'
              name="birthPlace"
              onChange={this.changeHandler} 
              value={this.state.birthPlace} 
              required={isTallPrice && this.state.vatSetting === false ? true : false}
        />
        <span>{i18n.t('Birthday')}</span>
        <MDBDatepicker  inline
                        customIcon='fa-regular fa-calendar-alt fa-lg'
                        format='yyyy-mm-dd'
                        max={date}
                        min={new Date(1900, 1, 1)}
                        value={this.state.birthDate}
                        selected={(this.state.birthDate && new Date(this.state.birthDate)) || null}
                        onChange={val => {
                          this.setState({birthDate: val});
                        }}
                        required={isTallPrice && this.state.vatSetting === false ? true : false}
                        />
                        
        </>}
       </>:false}
        
        
        
        <div
          className='toggle-with-label'
          onClick={() => this.setState({ addresseSame: !this.state.addresseSame })}
        >
          <Toggle
            checked={this.state.addresseSame}
            name='addressesSame'
            id='addressesSame'
            className="jc-toggle"
            readOnly
          />
          {i18n.t('Different_shipping_address')}
        </div>

        {this.state.addresseSame === true  ? <>
       <span>{i18n.t('Name')}</span>
       <input label='Shipping name input' 
             id='shippingName' 
             type='text'
             name="shippingName"
             onChange={this.changeHandler} 
             value={this.state.shippingName} 
             required={this.state.addresseSame === true  ? true : false}
       />
       <span>{i18n.t('Postcode')}</span>
       <input label='Shipping postcode input' 
             id='shippingPostcode' 
             type='textc'
             name="shippingPostcode"
             onChange={this.changeHandler} 
             value={this.state.shippingPostcode} 
             required={this.state.addresseSame === true  ? true : false}
       />
       <span>{i18n.t('City')}</span>
       <input label='Shipping city input' 
             id='shippingCity' 
             type='text'
             name="shippingCity"
             onChange={this.changeHandler} 
             value={this.state.shippingCity} 
             required={this.state.addresseSame === true  ? true : false}
       />
       <span>{i18n.t('Address')}</span>
       <input label='Shipping address input' 
             id='shippingAddress' 
             type='text'
             name="shippingAddress"
             onChange={this.changeHandler} 
             value={this.state.shippingAddress} 
             required={this.state.addresseSame === true  ? true : false}
       />
       <span>{i18n.t('Region')}</span>
       <input label='Shipping region input' 
             id='shippingRegion' 
             type='text'
             name="shippingRegion"
             onChange={this.changeHandler} 
             value={this.state.shippingRegion} 
       />
       <span>{i18n.t('Country')}</span>
       <input label='Shipping country input' 
             id='shippingCountry' 
             type='text'
             name="shippingCountry"
             onChange={this.changeHandler} 
             value={this.state.shippingCountry} 
             required={this.state.addresseSame === true  ? true : false}
       />
       <span>{i18n.t('Note')}</span>
       <input label='Shipping note input' 
             id='shippingNote' 
             type='text'
             name="shippingNote"
             onChange={this.changeHandler} 
             value={this.state.shippingNote} 
       />
       </>
        :false}
       
       </div>
       <div className="d-flex align-items-center justify-content-center my-2">
       <MDBBtn className="submit-button-style" type="submit">{i18n.t('Next')}</MDBBtn>
       </div>
       
      </form>
    );
  }
  
}

function ProfileFormFunc(props) {
      return <ProfileForm basketItems={props.basketItems} onShowValidData={props.onShowValidData}/>;
  }
  
  export default withTranslation()(ProfileFormFunc);