import React, { Component } from 'react';


class TermsAndConditions extends Component {

  render() {
    return (
    <>
    <p><strong className="font-weight-bold">General application and formation of contracts</strong></p>
    <p>The business proposals offered on the www.myensign.eu website apply exclusively to business partners. The business conditions of the online store outlined in what follows apply to the business transactions carried out between MYENSIGN D&iacute;szműforgalmaz&oacute; Kft. (hereinafter: Seller) and the business partners (hereinafter: Buyer).</p>
    <p>The general business conditions in effect at the time of concluding a contract or carrying out transactions are those legally binding upon the business transactions between the Parties. Different, contrary or modifying agreements shall not form part of the business contract unless they are approved in written form by both Parties.</p>
    <p><strong className="font-weight-bold">Forming contracts</strong></p>
    <p>By placing the <strong className="font-weight-bold">order,</strong> the Buyer states its final purchase offer and the Seller confirms the receipt of the order. The confirmation of the order does not mean that the order is accepted. It becomes binding upon the confirmation of the acceptance and the fulfilment of the payment for the goods.</p>
    <p>The Seller shall be entitled to limit the ordered quantity in line with commercial practices.</p>
    <p>Before concluding the order, the Buyer is presented with all the order data in a summarised form and has the opportunity to amend any errors made when providing the data.</p>
    <p>The Seller shall record the text of the order and, complying with legally-binding business conditions, shall send it attached to the concluded contract.</p>
    <p><strong className="font-weight-bold">Prices and payment terms</strong></p>
    <p>At the time of placing the order, the offered price is a fix price. The price shall include the legally-prescribed value-added tax, and its due amount.&nbsp;When delivering to non-EU countries, the Buyer is liable to pay the cost of the duties and commercial taxes.&nbsp;For special offers, the offered discount price is only valid for the duration of such offers.</p>
    <p>Delivering the item(s) does not entail any cost. Such costs are included in the shipping (FEDEX).&nbsp;The item(s) shall be payable by credit card, bank transfer or PayPal and the order becomes binding after the payment is received.&nbsp;The Buyer is entitled to counterclaim only if the counterclaim is indisputable or legally-binding.</p>
    <p><strong className="font-weight-bold">Transfer of risks</strong></p>
    <p>The risk of possible damage to the purchased item(s) is passed on to the Buyer upon the handover. The same applies to a handover delayed by the Buyer.</p>
    <p><strong className="font-weight-bold">Delivery and shipping fees</strong></p>
    <p>The Seller ships to EU countries, Switzerland, Norway, Russia, the USA, the United Arab Emirates, Australia and Japan.&nbsp;In order to make a purchase, the invoice address and the delivery address need to be in these countries.</p>
    <p>Delivery dates are binding only if recorded in writing. The delivery period shall start after the purchase price is received.&nbsp;The delivery period may be 14 working days, while in the case of countries subject to duty, this may even be as much as 20 days due to the differing customs handling practises. The shipment and delivery of the purchased goods will be completed by transportation or courier companies based on the level of the final value of the invoice. /FEDEX, GROUP 4 SECURITY/</p>
    <p>If the item(s) cannot be delivered due to reasons within the Buyer&rsquo;s control, extra delivery costs may arise. The Buyer shall bear the delivery costs of shipping to and fro in this case. This is not binding in regard to the conditions outlined in paragraph 8.</p>
    <p><strong className="font-weight-bold">Guarantee</strong></p>
    <p>The Buyer shall be entitled to choose whether they wish their guarantee to be validated by repair or by replacement delivery.</p>
    <p>If the guarantee service is faulty, the Buyer may decide that, instead of the fulfilment of the service, they would prefer a discount on the price, termination of the contract (withdrawal) or compensation. If the Buyer claims compensation instead of the fulfilment of the service, the warranties outlined in paragraphs 7.1 and 7.2 shall come into force. In respect of minor defects, the Buyer is not entitled to withdraw.</p>
    <p>The term of the guarantee is one year, starting from the delivery of the item(s).</p>
    <p><strong className="font-weight-bold">Warranty</strong></p>
    <p>In the case of a minor failure to comply with the obligations due to negligence, the Seller&rsquo;s warranty is predictable and is limited to typical average damage only. The same applies to the Seller&rsquo;s representative&rsquo;s or the Seller&rsquo;s subcontractor&rsquo;s failure to comply with the obligations due to negligence.</p>
    <p><strong className="font-weight-bold">Right to withdraw</strong></p>
    <p>The Buyer shall be entitled to withdraw its contractual declaration within 14 days in writing (e.g. letter, fax, email) without justification, or to withdraw the contractual declaration &ndash; if the item(s) have been delivered before the deadline expired &ndash; by returning the item(s).</p>
    <p>The deadline shall start after the delivery of such a notification in writing and not upon the handover of the item(s) on the recipient&rsquo;s side, nor at the time of fulfilling the information provision responsibility.</p>
    <p>The withdrawal statement shall be sent to the following address:</p>
    <p>MYENSIGN Díszműforgalmazó Kft.</p>
    <p>H-1094 Budapest, Liliom utca 48. III./6.</p>
    <p>HUNGARY</p>
    <p>info@myensign.eu</p>
    <p>In case of a successful withdrawal, both Parties shall return the received service. If the Buyer cannot return the received item(s) either partly or wholly, or is able to return it in a worse condition than it was originally, the Seller shall be compensated to the amount of the value of the returned item(s). This does not apply to cases when the disfunctionality of the item is solely linked to the checking of the item as would have been possible in a retail outlet.</p>
    <p>The Buyer shall bear the costs of returning the item(s) if the delivered item(s) match the ordered item(s). The repayment of the item(s) shall be resolved within 30 days. The deadline for the Buyer starts with the withdrawal statement and for the Seller with receiving the item(s).</p>
    <p><strong className="font-weight-bold">Data storage</strong></p>
    <p>The Buyer has been informed in detail about the personal data necessary for the completion of the order and about the type, size, place, function, appearance, use and right of withdrawal of the item(s).</p>
    <p>The Customer explicitly agrees to the use of his personal data. The Customer shall be entitled to withdraw from this agreement at any time.</p>
    <p><strong className="font-weight-bold">Competent court</strong></p>
    <p>The law of the Republic of Hungary shall apply to the Parties, with the exception of UN commercial law, whose application is hereby excluded.</p>
    <p><strong className="font-weight-bold">Written form</strong></p>
    <p>The contract may be supplemented and amended in written form only, in English.</p>
    <p><strong className="font-weight-bold">Other clauses</strong></p>
    <p>If one or more provisions are ineffective or inexecutable, all other provisions shall remain valid. The Parties may, in place of the provisions which are ineffective or inexecutable either partly or in whole, implement provisions accepted by both Parties which legally approximate to the business objectives of said ineffective or inexecutable provisions.</p>
    </>
    );
  }
}

export default TermsAndConditions;
