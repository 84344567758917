// react imports
import React from "react";

// MULTILANGUAGE
import { withTranslation } from 'react-i18next';
import i18n from '../i18n';
import ThankYouPanel from "./ThankYouPanel";



const ReturnPage = () => {

  const { t } = i18n;


  return (
    <div className="jc-page gray-bc checkout checkout-confirmation">
      <div className="container">
            <ThankYouPanel
              h1Title={t('Thank_you_for_shopping')}
              message={t('Your_order_will_be_shipped_soon')}
            />
      </div>
    </div>
  );
};

export default withTranslation()(ReturnPage);
