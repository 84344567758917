import { openDB } from 'idb';

export const dbName = 'order';
export const customerObjStoreName = 'customer';
export const basketObjStoreName = 'basket';

export async function getDB() {
    return await openDB(dbName, 1, {
        upgrade(db, oldVersion, newVersion, transaction) {
            db.createObjectStore(customerObjStoreName);
            db.createObjectStore(basketObjStoreName, { keyPath: 'basketId', autoIncrement: true});
        }
    });
}