// react imports
import React, { useContext, useRef, useState, useEffect } from "react";
import { useSearchParams, useLocation  } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { useSwipeable } from 'react-swipeable'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import i18n from '../i18n';
// CUSTOM COMPONENTS
import AppContext from '../AppContext';
import { ItemList } from '../data/MusterItem'
import backgroundImage from '../assets/media/images/hero.png'
import { addItemToBasket } from "../components/db/basket";
import logo from '../assets/media/logos/logo_2.png'
import basket_icon from '../assets/media/icons/basket.png'
import minus from '../assets/media/icons/minus.png'
import plus from '../assets/media/icons/plus.png'

import { MDBContainer, 
         MDBCol, 
         MDBRow
          } from 'mdb-react-ui-kit';
import SimilarProductCard from '../components/cards/SimilarProductCard'
import GalleryComponent from '../components/gallery/GalleryComponent'
import FooterModal from '../components/modals/FooterModal'


const DetailPage = (props) => {

  const location = useLocation();

  // STATES
  const [showModalOther, setShowModalOther] = useState(false);
  const [product, setProduct] = useState();
  const [isBodyScroll, setIsBodyScroll] = useState(false);
  const [modalText, setModalText] = useState(false);
  const [fullItemList, setFullItemList] = useState([]);
  const [menge, setMenge] = useState(0);
  const [starttime, setStarttime] = useState(false);
  const [onTouch, setOnTouch] = useState(false)
  // MULTILANGUAGE
  const { t } = i18n;

  const cardsRef  = useRef(null);

  useEffect(() => {
    setProduct(location.state.product)
    setFullItemList(ItemList);
  }, []);

  const addToBasket = () => {
    let finalproduct = {
      "title":product.title,
      "firstImageUrl": product.firstImageUrl,
      "description": product.description,
      "price": product.price,
      "mengeTyp": product.mengeTyp,
      "shippingcost": 0,
      "count": menge
    };
    addItemToBasket(finalproduct);
    //context.openLoginModal('basketmodal');
    showBasketModal();
  }

  const loadNewProducts = () => {
    var arrayB= ItemList
    var newArray = fullItemList.concat(arrayB);
    setFullItemList(newArray)
  };

 

	const closeModal = () => {
    setShowModalOther(false)
    setIsBodyScroll(false)
	}

	const showBasketModal = () => {
    setShowModalOther(true)
    setModalText(i18n.t('Basket'))
    setIsBodyScroll(true)
	}



  useEffect(() => {
    
    if(isBodyScroll){
      setTimeout(() => {
        document.body.style.overflow = 'hidden';
        }, 500)
			document.body.style.overflow = 'hidden'
			console.log(document.body.style.overflow)
		}else{
			document.body.style.overflow = 'scroll'
		}
  }, [isBodyScroll]);

  const responsive = {
    superWidthDesktop: {
        breakpoint: { max: 4000, min: 1480 },
        items: 3,
        partialVisibilityGutter: 1
      },
    small_2: {
      breakpoint: { max: 1479, min: 730 },
      items: 2,
      partialVisibilityGutter: 1
    },
    small_3: {
      breakpoint: { max: 729, min: 0 },
      items: 1,
      partialVisibilityGutter: 1
    }
  };

  const onTouchStart = () => {
    setStarttime(true)
  }
 
  const onTouchMove = (e) => {
    setStarttime(false)
  }

  const onTouchEnd = () => { 
    if(!starttime){
        setOnTouch(false)
    }else {
        setOnTouch(true)
    }
    
    
   }

  const handlers = useSwipeable({
    
    onSwipedLeft: () => {document.body.style.overflow = 'hidden'},
    onSwipedRight: () => {document.body.style.overflow = 'hidden'},
    onSwipedUp: () => {document.body.style.overflow = 'scroll'},
    onSwipedDown: () => {document.body.style.overflow = 'scroll'},
    onSwiping: () => {setStarttime(false)},
    onSwiped: () => {document.body.style.overflow = 'scroll'},
    onTap: () => {document.body.style.overflow = 'scroll'; setStarttime(true)}
   // onTouchStartOrOnMouseDown: () => console.log("swipe")
  });

  const refPassthrough = (el) => {
    handlers.ref(el);
    cardsRef.current = el;
  }

  return (
    <>
    
    <MDBContainer className="p-0" style={{maxWidth: "1366px"}}>

        <section className="detail-section" id="detail-section">
          {product !== undefined && (<>
          <div className="product-detail">
            <img src={product.firstImageUrl} alt={product.title} />
            <div className="product-content">

              <div className="product-title">
                  {product.title}
              </div>

              <div className="product-infos">
                  {product.description}
              </div>

              <div className="product-price-div">
                  <span className="euro">€ </span>
                  <span className="product-price">{product.price}<span className="menge-type"> / {product.mengeTyp}</span></span>
              </div>

            </div>
          </div>
          <div className="product-menge">
            <div className="product-input">
              <button type="button" onClick={() => setMenge(menge > 0 ? menge - 1 : 0)}>
                  <img src={minus} alt="minus"/>
              </button>
              <input id='menge' 
                    type='text'
                    name="menge"
                    onChange={(event) => setMenge(event.target.value)} 
                    value={menge} 
              />
              <button type="button" onClick={() => setMenge(menge + 1)}>
                  <img src={plus} alt="plus"/>
              </button>
            </div>
            <div className={menge > 0 ? "product-basket-button active" : "product-basket-button"}>
                <button type="button" onClick={addToBasket}>
                    <img src={basket_icon} alt="basket-icon"/>
                </button>
            </div>
          </div>
          </>
          )}
          
        </section>

        <section className="similar-items" id="similar-items">
          <div onTouchMove={onTouchMove} 
              onTouchStart={onTouchStart} 
              onTouchEnd={onTouchEnd} {...handlers} ref={refPassthrough}>
              <Carousel
                additionalTransfrom={0} 
                draggable={false}
                swipeable={true}
                showDots={false}
                responsive={responsive}
                infinite={true}
                renderArrowsWhenDisabled={true}
                renderDotsOutside={true}
                centerMode={false}
                keyBoardControl={false}
                customTransition="transform 1000ms ease-in-out"
                transitionDuration={1010}
                slidesToSlide={1}
                containerClass="carousel-container"
                autoPlay={false}
                removeArrowOnDeviceType={["small_3"]}
                
            >
                {fullItemList.map((k,n) => {
                  return (
                    <div className="py-3" key={n}>
                        <SimilarProductCard msg={k} setProduct={setProduct}/>
                    </div>
                  )
                })}
              </Carousel>
          </div>
        </section>
    </MDBContainer>
      {showModalOther ?
        <FooterModal
          show= {showModalOther}
          handleClose = {closeModal}
          text= {modalText}
        />:false}
    </>
  );
};

export default withTranslation()(DetailPage);
