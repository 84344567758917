// REACT IMPORTS
import React, { useContext, useState } from "react";
import AppContext from '../../../AppContext';
import { withTranslation } from 'react-i18next';
// MDBOOTSTRAP IMPORTS
import { MDBRow, MDBCol, MDBContainer } from "mdb-react-ui-kit";

import FooterModal from '../../modals/FooterModal'

import facebook_icon from '../../../assets/media/icons/facebook.png'

// CUSTOM COMPONENT IMPORTS


//images
import holzhandel_icon from '../../../assets/media/logos/basket-icon.png';


const Footer = (props) => {
  const context = useContext(AppContext);

  const [isActive, setIsActive] = useState("1");
  const [showModalOther, setShowModalOther] = useState(false);
  const [modalText, setModalText] = useState(false);

  const showModal = (text) => {
    setShowModalOther(true)
    setModalText(text)
  } 
  const closeModal = () => {
    setShowModalOther(false)
    setModalText("")
  }
  const getLanguage = (language) => {
    if(language === 'EN'){
      context.toEnglish();
      context.onRenderTrue();
    }else{
      context.toGerman();
      context.onRenderTrue();
    }
  };

  return (
    <footer className="holzhandel-footer">
     	<div className="template-footer">
					<MDBContainer className="px-0" style={{maxWidth: "1366px"}}>
						<div className=" bg-2 d-flex justify-content-center">
							<MDBRow className="m-0" style={{width: "100%"}}>
								<MDBCol center md="4" size="12" className="left-col-footer">
									<MDBRow className="m-0 pb-4 holzhandel-icon-row">
										<img src={holzhandel_icon} alt="holzhandel-icon" className="holzhandel-icon-footer"/>
									</MDBRow>
									<span className="holzhandel-footer-text-left">
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
									sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</span>
								</MDBCol>
								<MDBCol center md="4" size="12" className="middle-col-footer">
									<div className="modal-show-btn"><span>Product information</span></div>
									<div className="modal-show-btn"><span>Contact</span></div>
									<div className="modal-show-btn"><span>Resellers</span></div>
								</MDBCol>
								<MDBCol center md="4" size="12" className="right-col-footer">
									<button onClick={() => showModal("Terms and conditions")} className="modal-show-btn"><span>Terms and conditions</span></button>
									<button onClick={() => showModal("Data processing statement")} className="modal-show-btn"><span>Data processing statement</span></button>
									<button onClick={() => showModal("Shipping and billing data")} className="modal-show-btn"><span>Shipping and billing data</span></button>
								</MDBCol>
							</MDBRow>
						</div>
						<div className="black-footer">
							<MDBRow className="m-0" style={{width: "100%", height: "100%"}}>
								<MDBCol center md="6" size="12" className="d-flex justify-content-start align-items-center"> 
									<span className="black-footer-left-text">© AmHolzhandel all rights reserved</span>
								</MDBCol>
								<MDBCol center md="6"  size="12" className="d-flex justify-content-end align-items-center"> 
									<div className="d-flex justify-content-center align-items-center pe-3">
										<img src={facebook_icon} height='24' alt="facebook" style={{marginTop: "-4px"}}/>
									</div>
									<MDBRow>
										<MDBCol center md="6" size="6" className="d-flex justify-content-center align-items-center language-col-left"> 
											<span className={isActive === "1" ? "active" : ""} onClick={() => {getLanguage('EN'); setIsActive("1")}}>EN</span>
										</MDBCol>
										<MDBCol center md="6" size="6" className="d-flex justify-content-center align-items-center language-col-right"> 
											<span className={isActive === "2" ? "active" : ""} onClick={() => {getLanguage('DE'); setIsActive("2")}}>DE</span>
										</MDBCol>
									</MDBRow>
								</MDBCol>
							</MDBRow>
						</div>
					</MDBContainer>
				</div>
        {showModalOther ?
        <FooterModal
          show= {showModalOther}
          handleClose = {closeModal}
          text= {modalText}
        />:false}
    </footer>
  );
};

export default withTranslation()(Footer);
