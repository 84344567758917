import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import TermsAndConditions from '../../About/TermsAndConditions';
import LegalStatement from '../../About/LegalStatement';
import DataProtection from '../../About/DataProtection';
import ShipingAndBillingData from '../../About/ShipingAndBillingData';
import Contact from '../../About/Contact';
import ProductInformation from '../../About/ProductInformation';
import Basket from '../basket/Basket';
import ReturnPage from '../../stripe/ReturnPage';
import close from '../../assets/media/icons/close.png';
import holzhandel_icon from '../../assets/media/logos/basket-icon.png';
import {
  MDBContainer,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalBody
} from 'mdb-react-ui-kit';

import i18n from '../../i18n';

class FooterModal extends Component {

  toggle = nr => () => {
    let modalNumber = 'modal' + nr
    this.setState({
      [modalNumber]: !this.state[modalNumber]
    });
  }

  render() {
    const { t } = i18n;
    return (
      <MDBContainer>
        <MDBModal show={this.props.show} animationDirection='right' closeOnEsc={false} staticBackdrop >
        <MDBModalDialog position='top-right' side scrollable>
          <MDBModalContent>
            <MDBModalHeader>
              <div className="d-flex align-items-center justify-content-start" style={{width: "100%"}}>
              <button type="button" className='btn-close-basket'  onClick={this.props.handleClose} tabindex="-1"><img src={close}  alt="" /></button>
              </div>
              <div className="d-flex align-items-center justify-content-center" style={{width: "100%", marginTop: "10px"}}>
                <img alt="" className="holzhandel-logo-basket" src={holzhandel_icon}/>
              </div>
                  
            </MDBModalHeader>
          <MDBModalBody>
         
          <div>
            {this.props.text === t('Terms_and_conditions') ?
            <TermsAndConditions/>:false}
            {this.props.text === t('Legal_statement') ?
            <LegalStatement/>:false}
            {this.props.text === t('Data_processing_statement') ?
            <DataProtection/>:false}
            {this.props.text === t('Shipping_and_billing_data') ?
            <ShipingAndBillingData/>:false}
            {this.props.text === t('Contact') ?
            <Contact/>:false}
            {this.props.text === t('Product_information') ?
            <ProductInformation/>:false}
            {this.props.text === t('Basket') ?
            <Basket jewellery={this.props.jewellery}/>:false}
            {this.props.text === t('A_successful_shopping') ?
            <ReturnPage />:false}
          </div>
          
          </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
        </MDBModal>
      </MDBContainer>
    );
  }
}

export default withTranslation()(FooterModal);
