import React, { useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { MDBContainer } from 'mdb-react-ui-kit';
import CurrencySelector from './CurrencySelector';
import Payment from '../../stripe/Payment';
import { getAllBasketItems, deleteBasketById, updateBasketItem, updateBasketItems, getBasketItemsCount } from '../db/basket';
import { getCustomerInfo } from '../db/customer';
import {
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBLoadingManagement,
  MDBIcon  
} from 'mdb-react-ui-kit';
import InterB2BAxios from '../../Service/InterAxios';
import ProfileForm from './ProfileForm'
import { setCurrency } from '../db/customer';
import trash from '../../assets/media/icons/trash.png';
import mastercard from '../../assets/media/icons/logo-mastercard.svg';
import visa from '../../assets/media/icons/Visa_Logo.svg';
import stripe from '../../assets/media/images/image3.png';
import minus from '../../assets/media/icons/minus.png'
import plus from '../../assets/media/icons/plus.png'

import i18n from '../../i18n';
import Cookies from 'universal-cookie';
const cookies = new Cookies();


class Basket extends React.Component {
    state = {"basketItems": undefined, "badge": undefined, "currency": "EUR", isPayment: false, isLoading: true,
    isValidData: false};

    constructor(props) {
      
      super(props);
      this.listInnerRefPay = React.createRef();
      this.listInnerRefStripe = React.createRef();
      //this.interAxios = new InterB2BAxios(this, 'Basket');
    }

    componentDidMount() {
      this.loadBasket();
      getBasketItemsCount();
       this.onLoading();
    }

   /* async function initialize() {
      await this.loadBasket();
    }*/

    loadBasket = () => {
      //let self = this;
      
      getAllBasketItems().then((basketContent) => {
        /*self.interAxios.post('/price', basketContent, function(response) {
          updateBasketItems(response.data)
          self.setState({"basketItems": response.data, "badge": response.data.length});
        })*/
        console.log(basketContent);
        this.setState({"basketItems": basketContent, "badge": basketContent.length});
      });
    }

    clearBasket = (item) => {
      deleteBasketById(item).then(() => this.loadBasket());
    }

    deleteBasketById = (index) => {
      let item = this.state.basketItems[index];
      deleteBasketById(item).then(() => this.loadBasket());
    }

    decreaseCount = (index) => {
      let item = this.state.basketItems[index];
      item.count--;
      updateBasketItem(item).then(() => this.loadBasket());
    }

    increaseCount = (index) => {
      let item = this.state.basketItems[index];
      item.count++;
      updateBasketItem(item).then(() => this.loadBasket());
    }

    currencySelect = (currency) => {
      let self = this;
     /* this.interAxios.post('/price', this.state.basketItems, function(response) {
        updateBasketItems(response.data)
        .then(() => setCurrency(currency))
        .then(() => self.setState({currency: currency}))
        .then(() => self.loadBasket());
       
      })*/

    }

    summarizePrice = () => {
      let sumPrice;
      if (this.state.currency === 'EUR') {
        sumPrice = this.state.basketItems.map(item => item.price * item.count).reduce((total, value) => total+value, 0);
      } else if (this.state.currency === 'USD') {
        sumPrice = this.state.basketItems.map(item => item.usdPrice * item.count).reduce((total, value) => total+value, 0);
      } else if (this.state.currency === 'HUF') {
        sumPrice = this.state.basketItems.map(item => item.hufPrice * item.count).reduce((total, value) => total+value, 0);
      } else {
        sumPrice = 0;
      }
      return sumPrice;
    }

    goToPayment = () => {
      let self = this;
      getCustomerInfo().then((customer) => {
        getAllBasketItems().then((basketContent) => {
          if(customer &&  basketContent){
          /*  this.interAxios.post('/order', 
            {
              customer: customer,
              basket: basketContent,
              currency: this.state.currency,
              lang: cookies.get('mylanguage')?.language
            }, function(response) {
              self.setState({isPayment: true, returnId: response.data.returnId})
              setTimeout(() => {
                self.listInnerRefPay.current.scrollIntoView({
                });
                }, 500)
            });*/
            
          }else{
            alert(i18n.t('Fill_in_the_missing_information.'))
          }
        });
      });
    }

    onShowValidData = () => {

      this.setState({isValidData: true})
      setTimeout(() => {
        this.listInnerRefStripe.current.scrollIntoView({
        });
        }, 500)

    }

    onHideValidData = () => {
      this.setState({isValidData: false})
    }

    onLoading = () => {
      setTimeout(() => {
        this.setState({isLoading: false})
        }, 1500)

    }

    render() {
      if(this.state.basketItems === undefined){
        return <div ref={this.props.fontRef} style={{ height: "200px", width: "100%", zIndex: "1029" }}>
          <MDBLoadingManagement
            spinnerElement={<MDBIcon className='loading-icon' fas icon='spinner' size='2x' spin />}
            parentRef={this.props.fontRef}
          />
        </div>
      }else{
        if (this.state.basketItems.length === 0) {
          return <MDBContainer><h1>{i18n.t('Basket')}</h1>{i18n.t('Your_basket_is_empty.')}</MDBContainer>;
        } else {
          let basketItems = this.state.basketItems.map((value, index) => 
              <div key={index} >
                <MDBRow className="m-0 pb-4">
                  <MDBCol md="4" size="12" center>
                    <img src={value.firstImageUrl} width='120' alt=""/>
                  </MDBCol>
                  <MDBCol center size="6">
                    <MDBRow>
                      <span className="customized-text">{value.title}</span>
                    </MDBRow>
                    <div className="product-menge">
                      <MDBRow className="product-input">
                        <MDBCol className="ps-0 pe-1" md="auto" size="auto">
                          <button onClick={() => this.decreaseCount(index)}><img src={minus} alt="minus"/></button>
                        </MDBCol>
                        <MDBCol className="p-1" md="auto" size="auto">
                          <div className="menge-div">
                            <span className="value-count">{value.count}</span>
                          </div>
                        </MDBCol>
                        <MDBCol className="p-1" md="auto" size="auto">
                          <button onClick={() => this.increaseCount(index)}><img src={plus} alt="plus"/></button>
                        </MDBCol>
                      </MDBRow>
                   </div>
                  </MDBCol>
                  <MDBCol md="2" size="6" center className="d-flex justify-content-center">
                    <button className='trash'  onClick={() => this.clearBasket(value)}><img src={trash}  alt="" ></img></button>
                  </MDBCol>
                </MDBRow>
              </div>);
          let sumPrice = this.summarizePrice();
          return <>
            
            {basketItems}
            <hr/>
            
            <MDBRow className="m-0">
              <span className="payment-text">{i18n.t('Payment')}</span>
            </MDBRow>
            <MDBRow className="m-0">
              <MDBCol center>
                <span className="totalprice-text">{i18n.t('Total_price')}</span>
              </MDBCol>
              <MDBCol center>
                  <span className="price">{parseFloat(sumPrice).toFixed(2)} {this.state.currency === "EUR" ? "€" : 
                                                      this.state.currency === "USD" ? "$" : "ft"}</span>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>
              </MDBCol>
              <MDBCol><div className="line"/>
              <CurrencySelector onChange={this.currencySelect} currency={this.state.currency}/>
              </MDBCol>
              
            </MDBRow>
            <hr/>
            {this.state.basketItems.map(item => item.price * item.count).reduce((total, value) => total+value, 0) < 15000 ? <>
            <ProfileForm basketItems={this.state.basketItems} onShowValidData={this.onShowValidData}/>
            {this.state.isValidData && (<>
              <hr/>
              <MDBRow center ref={this.listInnerRefStripe}>
                  <img className="logo-mastercard" src={mastercard} alt=""/>
              
                  <img className="visa-logo" src={visa} alt=""/>
              </MDBRow>
              <MDBRow center className="py-3">
                <MDBBtn  type="button" className="stripe-button" onClick={this.goToPayment}>
                  <img src={stripe} alt=''/>
                </MDBBtn>
              </MDBRow></>
            )}
            
            {this.state.isPayment === true && (
             <div ref={this.listInnerRefPay}>
              <Payment returnId={this.state.returnId}/>
            </div>
            )}
            </> :
            <>
             <MDBRow className="m-0">
              <span className="payment-text">{i18n.t('Information')}</span>
              <span>{i18n.t('information_text')}</span>
             </MDBRow>
            </>}
           </>;
        }
      }
    
    }
}

function BasketFunc(props) {
  const fontRef = useRef(null);
  return <Basket fontRef={fontRef}/>;
}

export default withTranslation()(BasketFunc);
