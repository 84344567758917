import React, { Component } from 'react';
import AppContext from './AppContext';
import InterAxios from './Service/InterAxios';
import i18next from 'i18next';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const getAuthToken = () => {
  return cookies.get('authtoken')?.token;
}


class AppContextProvider extends Component {

  constructor(props) {
    super(props);
    this.state = {addresseSame: false,
      interAxios: new InterAxios('App', getAuthToken),
      activeItem: "1"
    };
  }

  componentDidMount() {
    this.toEnglish();
}

  toGerman = () => {
    i18next.changeLanguage('de', (err, t) => {
      if (err) return console.log('something went wrong loading', err);
      cookies.set('mylanguage', {
          language: 'de'
        }, { path: '/'}
      );
    });
    
    //moment.locale('de');
  }

  toEnglish = () => {
    i18next.changeLanguage('en', (err, t) => {
      if (err) return console.log('something went wrong loading', err);
      cookies.set('mylanguage', {
          language: 'en'
        }, { path: '/'}
      );
    });
    
    //moment.locale('en');
  }

 
  
  
  
  setState(state) {
    super.setState(function(prevState, props) {
      let newState = {...prevState, ...state};
      window.localStorage.setItem('state', JSON.stringify(newState));
      return {
        ...newState
      };
    })
  }


  getState = () => {
    let stateStr = window.localStorage.getItem('state');
    let stateObj = JSON.parse(stateStr);
    return stateObj;
  }

  clearState = () => {

  }

  

  openLoginModal = (modalName) => {
    this.setState({
      loginModalOpened: true,
      modalName: modalName
    });
  }

  isLoginModalOpened = () => {
    return this.getState()?.loginModalOpened;
  }
  closeLoginModal = () => {
    this.setState({
      loginModalOpened: false,
    });
    document.body.style.overflowY = 'scroll'
  }

  onHideScroll = (value) => {
    this.setState({isScroll: value})
  }

  onRender = () => {
    this.setState({isRender: false})
  }

  onRenderTrue = () => {
    this.setState({isRender: true})
  }

  onSetActiveItem = (tab) => {
    this.setState({activeItem: tab})
  }
  
  onSetActiveItemRespo = (tab) => {
    this.setState({activeItemRespo: tab})
  }

  onSetIsScroll = (value) => {
    this.setState({isScroll: value})
  }

  onSetNavChange = (value) => {
    this.setState({isNavChange: value})
  }

  onSetPathName = (pathname) => {
    this.setState({pathname: pathname})
  }



  render() {
      return (
          <AppContext.Provider
              value={{
                interAxios: this.state.interAxios,
                getState: this.getState,
                clearState: this.clearState,
                onHideScroll: this.onHideScroll,
                toGerman: this.toGerman,
                toEnglish: this.toEnglish,
                onRender: this.onRender,
                onRenderTrue: this.onRenderTrue,
                onSetActiveItem: this.onSetActiveItem,
                onSetActiveItemRespo: this.onSetActiveItemRespo,
                onSetIsScroll: this.onSetIsScroll,
                onSetPathName: this.onSetPathName,
                onSetNavChange: this.onSetNavChange,
                isLoginModalOpened: this.isLoginModalOpened,
                openLoginModal: this.openLoginModal,
                closeLoginModal: this.closeLoginModal,
              }}
          >
              {this.props.children}
          </AppContext.Provider>
      );
  }
}

export default AppContextProvider;
