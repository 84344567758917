// react imports
import React, { useContext, useEffect } from "react";
import { Outlet } from "react-router-dom";

import AppContext from '../../AppContext';


// MDBOOTSTRAP IMPORTS
import { MDBContainer } from "mdb-react-ui-kit";

// IMPORT LAYOUT COMPONENTS
import Footer from "./footer/Footer";
import Header from "./header/Header";





function Layout({ children }) {
  const context = useContext(AppContext);
  
  // STATES
  const scrollEventListener = () => {
    const pathname = window.location.pathname.split("/")[window.location.pathname.split("/").length-1];
    if(pathname !== "detailpage"){
      context.onSetNavChange(false)
      context.onSetIsScroll(window.scrollY > 40 );
      if(context.getState()?.isNavChange === false){
        if(document.getElementById('about-us').getBoundingClientRect().top === 0){
          context.onSetActiveItem("1");
        }else {
          if(window.scrollY <  document.getElementById('catalog').getBoundingClientRect().top + window.pageYOffset -100){
            
            if(window.scrollY <  document.getElementById('about-us').getBoundingClientRect().top + window.pageYOffset -100){
              context.onSetActiveItem("1");
            }
            if(window.scrollY > document.getElementById('about-us').getBoundingClientRect().top + window.pageYOffset -200){
              context.onSetActiveItem("2");
            }
          }
        }
        
        if(window.scrollY > document.getElementById('catalog').getBoundingClientRect().top + window.pageYOffset -100){
          context.onSetActiveItem("3");
        }
      
      }   
    }
  }
  
  useEffect(() => {
    document.addEventListener("scroll", scrollEventListener);
    return () => {
        document.removeEventListener("scroll", scrollEventListener);
    };
  }, []);
  

  

  return (
    <MDBContainer  fluid className='p-0'>
       <div className="main-navbar" id="main-navbar-id">
       <Header />
       </div>
      
      <main>
      <Outlet/>
      </main>
      <Footer />
      {/*context.getState()?.modalName === "signup" && (
        <SignUpModal
        visible={context.getState()?.loginModalOpened}
        hideModal={context.closeLoginModal}
        openLoginModal={context.openLoginModal}
        loginEvent={loginEvent}
        context={context}
      />
      )*/}
     
      
    </MDBContainer>
  );
}

export default Layout;
