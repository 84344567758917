import gallery_item_1 from '../../assets/media/images/gallery/gallery_1.png'

import gallery_item_2 from '../../assets/media/images/gallery/gallery_12.png'
import gallery_item_3 from '../../assets/media/images/gallery/gallery_4.png'
import gallery_item_4 from '../../assets/media/images/gallery/gallery_8.png'
import gallery_item_5 from '../../assets/media/images/gallery/gallery_9.png'
import gallery_item_6 from '../../assets/media/images/gallery/gallery_3.png'
import gallery_item_7 from '../../assets/media/images/gallery/gallery_6.png'


const GalleryComponent = () => {

  return (
      <div className="gallery-div"
      >
        <div className="gallery-first-element" >
          <img src={gallery_item_1} alt="first-element" />
        </div>
        <div className="gallery-second-element" >
            <div className="upper-element" >
                <img src={gallery_item_2} alt="second-element" />
            </div>
            <div className="bottom-element" >
                <img src={gallery_item_3} className="third-element" alt="third-element"/>
                <img src={gallery_item_4} className="fourth-element" alt="fourth-element" />
            </div>
        </div>
        <div className="gallery-third-element" >
          <img src={gallery_item_5} alt="fifth-element" />
        </div>
        <div className="gallery-fourth-element" >
            <div className="upper-element" >
                <img src={gallery_item_6} alt="sixth-element" />
            </div>
            <div className="bottom-element" >
                <img src={gallery_item_7} alt="seventh-element" />
            </div>
        </div>
      </div>
  );
};

export default GalleryComponent;
