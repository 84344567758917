import React, { Component } from 'react';


class LegalStatement extends Component {

  render() {
    return (
      <>
      <p align="center"><strong className="font-weight-bold">Legal notice</strong></p>
      <p>Any and all engineering, graphic, software and other solutions (such as those related to the form and content structure of the site, the designation of content elements or the colour arrangement) related to the display, operation and maintenance of the http://www.myensign.eu/ site (the &ldquo;Website&rdquo;) and data, information, icons, logos, trademarks, domain names and other marks shown and used on the Website or otherwise related thereto are copyright protected within the framework provided by current Hungarian law and international treaties and conventions and all financial rights concerning the same are exclusively invested with MYENSIGN KFT. (the &ldquo;Operator&rdquo;).</p>
      <p>Unless otherwise stated or indicated, the content available on the Website is the intellectual product and copyrighted work of the Operator. Such content may be used solely in accordance with relevant legal regulations unless expressly approved by the Operator in writing. Any such use shall be for private purposes only and must not be for the purpose of making or increasing income, whether directly and indirectly.</p>
      <p>Within the framework of private use, the user of the Website (the &ldquo;User&rdquo;) is, in addition to online access, only entitled to save certain parts thereof on the hard disk of the User&rsquo;s computer used for accessing the Internet and print the same. Users are, however, not entitled to reproduce, distribute, copy with any tools and/or in any form, adapt, store in a database or make available for download parts of the given content duplicated in this manner or to commercialise any content, whether for or without value received.</p>
      <p>As regards the link collections that are part of the Website, the thematic compilation and arrangement of the links published on the given page are the work of the editor of the given page. The copyright created as a result of such editorial activity and the use of the link collections in any form are the Operator&rsquo;s exclusive rights. Any granting of rights to these to any third parties may be made by the Operator only. While respecting the right of the editor of the given subpage, the Operator is also entitled to take action against any conduct violating the editors&rsquo; rights.</p>
      <p>The Operator does not consent to any other duplication or copying of the content of the Website or using frames in this respect (or otherwise allowing any third party to indicate the content of the Website as its own content).</p>
      <p>Information regarding the content available on the Website may only be used on the Internet by means of a linked reference and subject to the following conditions:</p>
      <p>&nbsp;</p>
      <p>- &nbsp; &nbsp; &nbsp; &nbsp;The source must be shown in a clearly visible manner in the form of a link. The link should point to the given content item.</p>
      <p>- &nbsp; &nbsp; &nbsp; &nbsp;Embedding the homepage or any subpage of the http://www.myensign.eu/ website into any other website (for example, by using iframes) is not permitted.</p>
      <p>- &nbsp; &nbsp; &nbsp; &nbsp;The linked website must not create the impression that either the http://www.myensign.eu/ website or the Operator supports the use or purchase of the services or products offered on that site unless otherwise provided for by written agreement made with the Operator acting as contracting party.</p>
      <p>- &nbsp; &nbsp; &nbsp; &nbsp;The linked website must not provide or contain any false information regarding the http://www.myensign.eu/ website or the legal relationship between the Operator and the operator of the linked site or, generally, any other services of the Operator.</p>
      <p>- &nbsp; &nbsp; &nbsp; &nbsp;The linked website must not contain any content which violates the law or rights of third parties or offends general taste.</p>
      <p>- &nbsp; &nbsp; &nbsp; &nbsp;In case of any violation of the rules on linking, the Operator is entitled to immediately remove the link referring to the website if the operator has not removed it despite warning and the Operator is also entitled to claim compensation for damage incurred in relation to the violation.</p>
      <p>Responsibility for the service</p>
      <p>The content of the Website is indicative only and the Website is used at the users&rsquo; own risk and responsibility. The Operator does not warrant that the functions available on the Website are running uninterruptedly or flawlessly, nor that any detected errors are immediately recovered or that the Website or the server ensuring access thereto are virus-free and/or do not contain any other harmful component.</p>
      <p>The Operator assumes no responsibility or guarantee for the use of the contents available and accessible on the Website or the consequences of use, or that the content of the Website is accurate, reliable, error-free or operating uninterruptedly.</p>
      <p>Pursuant to the provisions of Act CVIII of 2001 concerning certain aspects of electronic commerce and information society services (the &ldquo;Electronic Commerce Act&rdquo;), the Operator does not control in advance the content of information made available by the Users nor does it look for any facts or circumstances that indicate conduct of any illegal activity.</p>
      <p>The Operator disclaims all liability for any information, data, figures, graphics or other content elements the Users have made available and displayed on the Website (including those sent or posted by Users).</p>
      <p>The Operator expressly disclaims liability for any comments placed by Users on any forums or similar services or their content. The Operator reserves the right not to publish or to remove without prior notice or upon the request of the users of the Website any such content - according to rules set by the Operator - if such content violates the law or the legitimate interests of any third person. The same applies to advertisements and other messages posted by Users on the Website or submitted for that purpose.</p>
      <p>The Operator is not responsible under any circumstances for any errors or damage caused due to reasons beyond its control (for example, technical failure or interruptions in the Internet network), including the destruction or late arrival of messages sent in electronic format or any damage caused by them.</p>
      <p>Only the person who continues the conduct concerned is responsible, and the Operator disclaims all liability for any damage caused by or arising from forms of conduct violating the security of information systems (including the use or dissemination of viruses or other software capable of causing damage, unauthorised access to or destruction or disclosure of personal and other data in the Operator&rsquo;s possession, or any other hacker activities).</p>
      <p>Where necessary, the Operator will use its best efforts to assist the competent authorities in identifying the perpetrators of actions in violation of law or of the rights of third parties.</p>
      <p>The Operator disclaims all liability for any information published by Users on the Internet by using the MyEnsign.eu sites. If the Operator becomes aware that any user entered, during registration, the personal data of a third person or otherwise in a manner in violation of the rights of any third person or law, or is using personal data or other data not qualifying as personal data which are publicly available on the MyEnsign.eu sites pages or have been obtained unlawfully in a manner violating the personal rights of any third person or in violation of law (e.g. spam), or is otherwise in breach of these privacy regulations or has caused damage while using the MyEnsign.eu sites, the Seller shall take the necessary legal action to remedy the damage caused and to have the person acting in the above manner made responsible before the law. In such cases, the Operator will use its best efforts to assist the competent authorities in establishing the identity of the offender and making him or her responsible.</p>
      <p>Legal consequences of breaching the provisions of the GCTC:</p>
      <p>In case of any violation of the rules laying down the conditions of the use of the Website, the Operator shall immediately take the legal actions necessitated by the violation or offence, namely initiating civil proceedings in case of civil law claims and the notification and removal procedure regulated in the Electronic Commerce Act or, if crime is suspected, bringing charges, in all other cases, notifying the authority competent for the case and cooperating with the acting bodies.</p>
  </>
    );
  }
}

export default LegalStatement;
