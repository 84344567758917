import foto_1 from '../assets/media/images/card-fotos/1.png';
import foto_2 from '../assets/media/images/card-fotos/2.png';
import foto_3 from '../assets/media/images/card-fotos/3.png';
import foto_4 from '../assets/media/images/card-fotos/4.png';
import foto_5 from '../assets/media/images/card-fotos/6.png';
import foto_6 from '../assets/media/images/card-fotos/5.png';

export const ItemList = [
            {
                id: 1,
                firstImageUrl: foto_1,
                title: "Lorem Ipsum dolor 200cm/15cm/3cm",
                description: "Lorem Ipsum dolor",
                price: "10",
                mengeTyp: "piece"
            },
            {
                id: 2,
                firstImageUrl: foto_2,
                title: "Lorem Ipsum dolor 20 kg",
                description: "Lorem Ipsum dolor",
                price: "34.50",
                mengeTyp: "bag"
            },
            {
                id: 3,
                firstImageUrl: foto_3,
                title: "Lorem Ipsum dolor 200cm/15cm/3cm",
                description: "Lorem Ipsum dolor",
                price: "10",
                mengeTyp: "piece"
            },
            {
                id: 4,
                firstImageUrl: foto_4,
                title: "Lorem Ipsum dolor 200cm/15cm/3cm",
                description: "Lorem Ipsum dolor",
                price: "10",
                mengeTyp: "piece"
            },
            {
                id: 5,
                firstImageUrl: foto_5,
                title: "Lorem Ipsum dolor 40kg",
                description: "Lorem Ipsum dolor",
                price: "42.40",
                mengeTyp: "bag"
            },
            {
                id: 6,
                firstImageUrl: foto_6,
                title: "Lorem Ipsum dolor 200cm/15cm/3cm",
                description: "Lorem Ipsum dolor",
                price: "10",
                mengeTyp: "piece"
            }
]