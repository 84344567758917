// REACT IMPORTS
import { useEffect, useContext, useState } from "react";

// REACT ROUTER IMPORTS
import { useNavigate } from "react-router";
// MDBoostrap IMPORTS

// MULTILANGUAGE
import { withTranslation } from 'react-i18next';

const SimilarProductCard = (props) => {

const navigate = useNavigate();

  return (
      <div 
        className="similar-product-card"
      >
        <div className="product-image-wrapper"  onClick={() => {props.setProduct(props.msg); setTimeout(() => {window.scrollTo({
                                                          top: 0,
                                                          behavior: "smooth"
                                                        }) },300);}}>
          <img src={props.msg.firstImageUrl} alt={props.title} />
        </div>
        <div className="product-content">
            <div className="product-title">
                <span>{props.msg.title}</span>
            </div>
        </div>
      </div>
  );
};

export default withTranslation()(SimilarProductCard);
