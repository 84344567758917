import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AppContextProvider from './AppContextProvider';
import Layout from './components/layout/Layout';
import HomePage from './pages/HomePage';
import DetailPage from './pages/DetailPage';


function App() {
  return (
    <AppContextProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout/>}>
            <Route path="" element={<HomePage />} />
            <Route path="/homepage" element={<HomePage />} />
            <Route path="/detailpage" element={<DetailPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </AppContextProvider>
  );
}

export default App;
