

export const StripeKey = {
    stripeKey: 'pk_test_51JclRAEzkMTqHrc6lksp2TkZlOiGAqiSurQr3waBRL2A6MCwodUrWrtuiRk7lCNFQmwEEGBp3G6681gOlC3Brr1300uqthHrMM'
};

export const StripeReturnUrl = {
    url: 'https://holzhandel.interb2b.com?stirpeConfirmation=true'
};


