// REACT IMPORTS
import { useEffect, useContext, useState } from "react";

// REACT ROUTER IMPORTS
import { useNavigate } from "react-router";
// MDBoostrap IMPORTS

// MULTILANGUAGE
import { withTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { addItemToBasket } from "../db/basket";
import AppContext from '../../AppContext';
import basket_icon from '../../assets/media/icons/basket.png'
import minus from '../../assets/media/icons/minus.png'
import plus from '../../assets/media/icons/plus.png'

const ProductCard = (props) => {

  const navigate = useNavigate();
  const context = useContext(AppContext);
  // STATES
  const [menge, setMenge] = useState(0);

  const addToBasket = () => {
    let product = {
      "title":props.msg.title,
      "firstImageUrl": props.msg.firstImageUrl,
      "description": props.msg.description,
      "price": props.msg.price,
      "mengeTyp": props.msg.mengeTyp,
      "shippingcost": 0,
      "count": menge
    };
    addItemToBasket(product);
    //context.openLoginModal('basketmodal');
    props.showBasketModal();
    setTimeout(() => {
      setMenge(0)
      }, 500)
  }

  const leerBasket = () => {

  }
  // MULTILANGUAGE

  useEffect(() => {
    
  }, []);


  return (
      <div 
        className="product-card"
      >
        <div className="product-image-wrapper" onClick={() => {context.onSetActiveItem("0");
                                    navigate('/detailpage', {
                                                          state: {
                                                            product: props.msg,
                                                          }
                                                        }); setTimeout(() => {window.scrollTo({
                                                          top: 0,
                                                          behavior: "smooth"
                                                        }) },300);}}>
          <img src={props.msg.firstImageUrl} alt={props.msg.title} />
        </div>
        <div className="product-content">

            <div className="product-title">
                {props.msg.title}
            </div>

            <div className="product-infos">
                {props.msg.description}
            </div>

            <div className="product-price-div">
                <span className="euro">€ </span>
                <span className="product-price">{props.msg.price}<span className="menge-type"> / {props.msg.mengeTyp}</span></span>
            </div>

        </div>
        <div className="product-menge">
            <div className="product-input">
              <button type="button" onClick={() => setMenge(menge > 0 ? menge - 1 : 0)}>
                  <img src={minus} alt="minus"/>
              </button>
              <input id='menge' 
                    type='text'
                    name="menge"
                    onChange={(event) => setMenge(event.target.value)} 
                    value={menge} 
              />
              <button type="button" onClick={() => setMenge(menge + 1)}>
                  <img src={plus} alt="plus"/>
              </button>
            </div>
            <div className={menge > 0 ? "product-basket-button active" : "product-basket-button"}>
                <button type="button" onClick={menge > 0 ? addToBasket : leerBasket}>
                    <img src={basket_icon} alt="basket-icon"/>
                </button>
            </div>
        </div>
      </div>
  );
};

export default withTranslation()(ProductCard);
